document.addEventListener("DOMContentLoaded", function () {
  const buyCourseButton = document.getElementById("buyCourseButton");
  const emailInput = document.getElementById("emailInput");
  const form = document.querySelector(".hero__form");

  buyCourseButton.addEventListener("click", function () {
    emailInput.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });

    setTimeout(() => {
      emailInput.focus();
      emailInput.classList.add("hero__input-highlighted");
    }, 500);
  });

  emailInput.addEventListener("input", function () {
    emailInput.classList.remove("hero__input-highlighted");
  });

  form.addEventListener("submit", async function (event) {
    event.preventDefault();

    const email = emailInput.value.trim();

    if (validateEmail(email)) {
      try {
        const response = await fetch(
          "https://functions.yandexcloud.net/d4eb7mm7e7r0n6j5e3td",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }),
          }
        );

        const data = await response.json();

        if (data.payformResponse) {
          window.location.href = data.payformResponse;
        } else {
          console.error("Ошибка при получении ссылки на оплату:", data);
        }
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    } else {
      emailInput.classList.add("hero__input-highlighted");
    }
  });

  function validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
});
